import { Button, Dialog } from "@radix-ui/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useInputToken, useOutputToken } from "@/app/features/swap/slice";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useNetwork } from "wagmi";

export default function WaitTsx({
  isApproving = false,
  hash = "",
  isMinting = false,
}) {
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const { chain } = useNetwork();

  return (
    <Dialog.Root open>
      <Dialog.Content style={{ maxWidth: 450 }} className="z-50 text-center">
        <Dialog.Title>
          {isApproving
            ? `Approving spend limit for ${inputToken?.symbol}`
            : isMinting
              ? "Minting NFT"
              : `Swapping ${inputToken?.symbol} for ${outputToken?.symbol}`}
        </Dialog.Title>
        <div className="py-6 text-9xl text-greenA-11">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
        <Dialog.Description size="2" mb="4">
          Please wait for transaction to be mined.
        </Dialog.Description>

        <div className="mt-8 w-full">
          <Dialog.Close>
            <Button
              tabIndex={-1}
              variant="soft"
              color="green"
              className="swap-button w-full"
              asChild
            >
              <Link
                href={`${chain?.blockExplorers?.default.url}/tx/${hash}`}
                target="_blank"
              >
                View transaction <ExternalLinkIcon />
              </Link>
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
