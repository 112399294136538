import { Button, Flex, Text } from "@radix-ui/themes";
import { formatBigIntCompact } from "@/common/utils";
import { SerializedTokenType } from "@/app/features/swap/slice";
import { useAccount, useBalance, useChainId } from "wagmi";
import { useCallback, useMemo } from "react";
import { NATIVES } from "@/app/features/swap/constants";
import { formatUnits } from "viem";
import { useQuoteCanSwap } from "@/app/features/swap/hooks";

interface BalanceProps {
  isOutput?: boolean;
  isLoading?: boolean;
  token: SerializedTokenType;
  onAmountChange?: (val: string) => void;
}
export default function Balance({
  isOutput = false,
  token,
  onAmountChange,
  isLoading,
}: BalanceProps) {
  const { gasPrice } = useQuoteCanSwap(isLoading);
  const chainId = useChainId();
  const { address } = useAccount();
  const native = useMemo(() => NATIVES[chainId || 1], [chainId]);
  const isNative = useMemo(
    () => token?.address === native.address,
    [native.address, token?.address],
  );
  const { data: balance, isLoading: isFetching } = useBalance({
    address,
    token: !isNative ? token?.address : undefined,
    watch: true,
    chainId,
  });

  const addMax = useCallback(() => {
    const max =
      (balance?.value || 0n) - (isNative ? gasPrice + gasPrice / 8n : 0n) || 0n;
    onAmountChange?.(formatUnits(max > 0n ? max : 0n, token?.decimals || 18));
  }, [balance?.value, gasPrice, isNative, onAmountChange, token?.decimals]);

  return (
    <Text className="text-grayA-11" size="2" weight="medium" as="span">
      {isFetching ? (
        <div className="flex h-6 items-center">
          <span className="flex h-4 w-36 animate-pulse rounded bg-gray-4"></span>
        </div>
      ) : (
        <Flex gap="2" align="center" className="h-6">
          {balance && (
            <>
              <span>
                {`Balance: ${formatBigIntCompact(balance.formatted)}`}
              </span>
              {!isOutput && balance.value > 0n && (
                <Button size="1" variant="surface" onClick={addMax}>
                  MAX
                </Button>
              )}
            </>
          )}
        </Flex>
      )}
    </Text>
  );
}
