import { Button, Callout, Dialog } from "@radix-ui/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useInputToken, useOutputToken } from "@/app/features/swap/slice";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useNetwork } from "wagmi";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";

interface ErrorTsxProps {
  isApproving?: boolean;
  isMinting?: boolean;
  hash?: `0x${string}`;
  retry?: () => void;
  reset?: () => void;
  error: any;
}

export default function ErrorTsx({
  isApproving = false,
  isMinting = false,
  retry = () => {},
  reset,
  error,
}: ErrorTsxProps) {
  const { code, message } = error.cause;
  return (
    <Dialog.Root open>
      <Dialog.Content style={{ maxWidth: 450 }} className="z-50 text-center">
        <Dialog.Title>
          {isApproving
            ? `Approval failed`
            : isMinting
              ? "NFT Mint failed"
              : `Swapping failed`}
        </Dialog.Title>
        <div className="py-6 text-9xl text-redA-11">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <Dialog.Description size="2" mb="4">
          <Callout.Root color="red">
            <Callout.Text>
              {code ? `${code}: ${message}` : message}
            </Callout.Text>
          </Callout.Root>
        </Dialog.Description>

        <div className="mt-8 flex w-full gap-2">
          <Dialog.Close>
            <Button
              tabIndex={-1}
              variant="soft"
              onClick={reset}
              color="green"
              className="swap-button flex-1"
            >
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button
              tabIndex={-1}
              color="green"
              className="swap-button flex-1"
              onClick={retry}
            >
              Retry
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
